const intialState = {
    isAddCenter: false,
    sectionList: [],
    centerList: '',
    isListLoading: true,
    theme: (JSON.parse(localStorage.getItem('theme')) || {})
};

export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Is_Add_Center':
            stateCopy.isAddCenter = true;
            return stateCopy;
        case 'Edit_Campus':
            stateCopy.successMessage = true;
            stateCopy.successMessageText = 'Campus details updated';
            return stateCopy;
        case 'Get_Program_List':
            stateCopy.programsCopy = action.payload;
            stateCopy.programs = stateCopy.programsCopy.map(el => [{ value: el._id, label: el.program }][0]);
            return stateCopy;
        case 'Get_Centers_Only':
            stateCopy.centers = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
            return stateCopy;
        case 'Get_Section_List':
            stateCopy.sectionList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Center_List':
            stateCopy.centerList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Back_To_Account':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Add_Center':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Edit_Center':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Add_Section':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Edit_Section':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Reset_List':
            stateCopy.isListLoading = true;
            return stateCopy;
        case 'GET_COLORS':
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--dark-background', JSON.parse(localStorage.getItem('theme'))?.headerColor);
            return stateCopy;
        case 'APPLY_THEME':
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            return stateCopy;
        default:
            return stateCopy;
    }
}