let intialState = {
    openIndividual: false,
    dataLoading: true
}

export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Open_Individual':
            stateCopy.openIndividual = true;
            stateCopy.centerObj = action.payload;
            return stateCopy;
        case 'Close_Individual':
            stateCopy.openIndividual = false;
            stateCopy.centerObj = {};
            return stateCopy;
        case 'Student_Trends':
            stateCopy.dates = action.payload.dates;
            stateCopy.num_of_logins = action.payload.num_of_logins;
            stateCopy.score = action.payload.num_of_engagementscore;
            return stateCopy;
        case 'Student_List':
            stateCopy.studentList = action.payload.result.map(el => [{ name: el.name, program: el.program, section: el.section, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_page = action.payload.total_page;
            return stateCopy;
        case 'Reset_Student_Details':
            stateCopy.scoreDetails = '';
            stateCopy.months = [];
            stateCopy.contents = [];
            stateCopy.subjects = [];
            stateCopy.subjectContent = [];
            stateCopy.subject = [];
            stateCopy.complete = [];
            stateCopy.dataLoading = true;
            return stateCopy;
        case 'Student_Report':
            stateCopy.scoreDetails = action.payload.data;
            stateCopy.months = action.payload.month;
            stateCopy.contents = action.payload.contents;
            stateCopy.subjects = action.payload.subjects;
            stateCopy.subjectContent = action.payload.subjectContent;
            stateCopy.logindates = action.payload.logins.dates;
            stateCopy.num_of_login = action.payload.num_of_logins;
            stateCopy.dataLoading = false;
            var len = action.payload.data.total_semesters;
            var arr = []
            for (var i = 0; i < len; i++) {
                if (action.payload.data.current_semester === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true,
                        user_id: action.payload.id
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false,
                        user_id: action.payload.id
                    });
                }
            }
            stateCopy.sems = arr;
            stateCopy.semsforTest = arr;
            return stateCopy;
        case 'Get_Subject_Completion':
            stateCopy.subject = action.payload.subject;
            stateCopy.subjectComplete = action.payload.complete;
            return stateCopy;
        case 'Get_Active_Sem':
            stateCopy.subject = action.payload.data.subject;
            stateCopy.subjectComplete = action.payload.data.complete;
            stateCopy.sems.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            return stateCopy;
        case 'Get_Test_Completion':
            stateCopy.test = action.payload.subject;
            stateCopy.testComplete = action.payload.complete;
            return stateCopy;
        case 'Get_Active_Sem_For_Test':
            stateCopy.test = action.payload.data.subject;
            stateCopy.testComplete = action.payload.data.complete;
            stateCopy.semsforTest.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            return stateCopy;
        case 'Get_Program':
            stateCopy.programs = action.payload.map(el => [{ value: el._id, label: el.program, value1: 'program' }][0]);
            return stateCopy;
        case 'Get_Section_Lists':
            stateCopy.sections = action.payload.map(el => [{ value: el._id, label: el.title, program_id: el.program._id, semester: el.program.semester, value1: 'section' }][0]);
            return stateCopy;

        // sections

        case 'Get_Section_Graph':
            stateCopy.sectionList = action.payload.sections;
            stateCopy.engagementScore = action.payload.engagement_score_count;
            stateCopy.studentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Section_Data':
            stateCopy.sectionData = action.payload.data.map(el => [{ title: el.title, program: el.program, center: el.center, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            stateCopy.total_section_page = action.payload.total_page;
            return stateCopy;
        case 'Get_Section_Student_Data':
            stateCopy.sectionStudentList = action.payload.result.map(el => [{ name: el.name, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_student_page = action.payload.total_page;
            return stateCopy;
        case 'Reset_Section_Details':
            stateCopy.sectionStudentList = [];
            stateCopy.total_student_page = [];
            return stateCopy;
        case 'Get_Section_Trends':
            stateCopy.sectionDates = action.payload.dates;
            stateCopy.section_engagement_score = action.payload.num_of_engagementscore;
            stateCopy.section_logins = action.payload.num_of_logins;
            return stateCopy;

        //programs

        case 'Get_Program_Graph':
            stateCopy.programStudentGraph = action.payload.result.map(el => [{ name: el.name, y: el.student_count }][0]);
            return stateCopy;
        case 'Get_Top_Program_Graph':
            stateCopy.topProgramGraph = action.payload.result.map(el => [{ color: el.color, y: el.engagement_score }][0]);
            stateCopy.programName = action.payload.result.map(el => [el.name][0])
            stateCopy.legands = action.payload.result.map(el => [{ name: el.name, color: el.color }][0])
            return stateCopy;
        case 'Get_Program_Data':
            stateCopy.programData = action.payload.data.map(el => [{ program: el.program, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;
        case 'Reset_Program_Details':
            return stateCopy;
        case 'Get_Top_Semester_Graph':
            stateCopy.topSemesterGraph = action.payload.data.map(el => [{ color: el.color, y: el.engagement_score }][0]);
            stateCopy.semNames = action.payload.data.map(el => [el.semester][0])
            stateCopy.semLegands = action.payload.data.map(el => [{ name: el.semester, color: el.color }][0])
            return stateCopy;
        case 'Get_Semester_Student_Graph':
            stateCopy.semesterStudentGraph = action.payload.data.map(el => [{ name: el.semester, y: el.student_count }][0]);
            return stateCopy;
        case 'Get_Program_Semester_Data':
            stateCopy.semestermData = action.payload.data.map(el => [{ semester: el.semester, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;

        //centers

        case 'Get_Center_Graph':
            stateCopy.centerList = action.payload.sections;
            stateCopy.centerEngagementScore = action.payload.engagement_score_count;
            stateCopy.centerStudentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Center_Data':
            stateCopy.centerData = action.payload.data.map(el => [{ title: el.title, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            stateCopy.total_center_page = action.payload.total_page;
            return stateCopy;
        case 'Get_Center_Program_Graph':
            stateCopy.centerProgramsList = action.payload.programs;
            stateCopy.centerProgramEngagementScore = action.payload.engagement_score_count;
            stateCopy.centerProgramStudentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Center_Program_Data':
            stateCopy.centerProgramData = action.payload.data.map(el => [{ program: el.program, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins }][0]);
            return stateCopy;
        case 'Reset_Center_Details':
            stateCopy.centerProgramsList = [];
            stateCopy.centerProgramEngagementScore = [];
            stateCopy.centerProgramStudentCount = [];
            stateCopy.centerProgramData = [];
            return stateCopy;

        default:
            return stateCopy;

    }
}