let intialState = {
    isResourceLoading: true,
    contents: [],
    documentContentState: false,
    isLoading: true,
    isShareFeedPopup: false
}

export default function myContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_All_Content':
            stateCopy.contents = action.payload;
            stateCopy.isResourceLoading = false;
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Get_Next_Content':
            if (action.payload.length > 0) {
                stateCopy.contents = stateCopy.contents.concat(action.payload);
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.isLoading = false;
            }

            return stateCopy;
        case 'Add_Content':
            stateCopy.contents = [action.payload, ...stateCopy.contents];
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case 'Add_Content_Question':
            stateCopy.contents = [action.payload, ...stateCopy.contents];
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case 'Change_Content_Status':
            stateCopy.contents.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case "Get_Content_State":
            stateCopy.documentContentState = action.payload;
            return stateCopy;
        case "Edit_Content":
            stateCopy.documentContentState = false;
            return stateCopy;
        case 'Search_Result':
            stateCopy.contents = action.payload;
            stateCopy.isResourceLoading = false;
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Get_Content_Question':
            stateCopy.questionData = action.payload;
            return stateCopy;
        case 'Share_Popup_Open':
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case 'Share_Popup_Close':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Share_Done':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        default:
            return stateCopy;

    }
}