import { getSemesterArr } from '../Components/miscelleneous'
let intialState = {
    studentList: [],
    staffList: [],
    sections: [],
    centers: [],
    programs: [],
    semesters: [],
    isListLoading: true,
    isSelectTab: false,
}

export default function testReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Students':
            stateCopy.studentList = action.payload.data.map(item => ({ ...item, isChecked: false }));
            stateCopy.isListLoading = false;
            stateCopy.studentListCopy = action.payload.data.map(item => ({ ...item, isChecked: false }));
            stateCopy.total = action.payload.total;
            stateCopy.active = action.payload.active;
            stateCopy.inactive = action.payload.inactive;
            stateCopy.invited = action.payload.invited;
            stateCopy.totalPage = action.payload.total_page;
            stateCopy.activeTab = 'student';
            return stateCopy;
        case 'Get_Search_List':
            stateCopy.studentList = action.payload.data.map(item => ({ ...item, isChecked: false }));
            return stateCopy
        case 'Add_Staff':
            stateCopy.activeTab = 'staff';
            // this.props.history.goBack();
            return stateCopy;
        case 'Get_Staffs':
            stateCopy.staffList = action.payload.data.map(item => ({ ...item, isChecked: false }));
            stateCopy.staffListCopy = action.payload.data.map(item => ({ ...item, isChecked: false }));
            stateCopy.isListLoading = false;
            stateCopy.totalStaff = action.payload.total;
            stateCopy.activeStaff = action.payload.active;
            stateCopy.inactiveStaff = action.payload.inactive;
            stateCopy.invitedStaff = action.payload.invited;
            stateCopy.totalStaffpage = action.payload.total_page;
            stateCopy.activeTab = 'staff';
            return stateCopy;
        case "Select_All_Student":
            if (action.payload) {
                stateCopy.studentList = stateCopy.studentList.map(item => ({ ...item, isChecked: true }));
                stateCopy.studentListCopy = stateCopy.studentListCopy.map(item => ({ ...item, isChecked: true }));
            }
            else {
                stateCopy.studentList = stateCopy.studentList.map(item => ({ ...item, isChecked: false }));
                stateCopy.studentListCopy = stateCopy.studentListCopy.map(item => ({ ...item, isChecked: false }));
            }
            return stateCopy
        case 'Select_All_Staff':
            if (action.payload) {
                stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: true }));
                stateCopy.staffListCopy = stateCopy.staffListCopy.map(item => ({ ...item, isChecked: true }));
            }
            else {
                stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: false }));
                stateCopy.staffListCopy = stateCopy.staffListCopy.map(item => ({ ...item, isChecked: false }));
            }
            return stateCopy
        case "Select_One_Student":
            if (action.payload.checked) {
                stateCopy.studentList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.studentList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy;
        case 'Select_One_Staff':
            if (action.payload.checked) {
                stateCopy.staffList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.staffList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy
        case 'Get_Reset_List':
            stateCopy.isListLoading = true;
            return stateCopy
        case 'Get_All_Center':
            stateCopy.centers = action.payload.map(el => [{ value: el._id, label: el.title, value1: 'center' }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_All_Sections':
            stateCopy.sections = action.payload.map(el => [{ value: el._id, label: el.title, program_id: el.program._id, semester: el.program.semester, value1: 'section' }][0]);
            return stateCopy;
        case 'Get_Semester_From_Program':
            const total = stateCopy.programsCopy.filter(el => el._id === action.payload)[0].semester;
            stateCopy.semesters = getSemesterArr(total);
            return stateCopy;
        case 'Get_All_Programs':
            stateCopy.programsCopy = action.payload;
            stateCopy.programs = stateCopy.programsCopy.map(el => [{ value: el._id, label: el.program, value1: 'program' }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_All_Subject':
            stateCopy.subjects = action.payload.map(el => [{ value: el._id, label: el.title, value1: 'subject' }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Filtered_List':
            stateCopy.studentList = action.payload.data;
            stateCopy.total = action.payload.total;
            stateCopy.active = action.payload.active;
            stateCopy.inactive = action.payload.inactive;
            stateCopy.invited = action.payload.invited;
            stateCopy.totalPage = action.payload.total_page;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Filtered_Staff_List':
            stateCopy.staffList = action.payload.data;
            stateCopy.totalStaff = action.payload.total;
            stateCopy.activeStaff = action.payload.active;
            stateCopy.inactiveStaff = action.payload.inactive;
            stateCopy.invitedStaff = action.payload.invited;
            stateCopy.totalStaffpage = action.payload.total_page;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Clear_Student_Filters':
            // if (action.payload === 'student') {
            //     stateCopy.studentList = stateCopy.studentListCopy;
            // }
            // else {
            //     stateCopy.staffList = stateCopy.staffListCopy;
            // }
            return stateCopy;
        case 'Toggle_Status':
            if (action.payload.type === 'student') {
                stateCopy.studentList.forEach(element => {
                    if (element._id === action.payload.id) {
                        element.status = (element.status === 'inactive' ? 'active' : element.status === 'invited' ? 'inactive' : 'inactive');
                    }
                });
            }
            else {
                stateCopy.staffList.forEach(element => {
                    if (element._id === action.payload.id) {
                        element.status = (element.status === 'inactive' ? 'active' : element.status === 'invited' ? 'inactive' : 'inactive');
                    }
                });
            }
            return stateCopy;
        case 'Check_User':
            stateCopy.exists = action.payload;
            return stateCopy;
        case 'View_Student':
            stateCopy.student = stateCopy.studentList.filter(el => el._id === action.payload)[0];
            stateCopy.isView = true;
            return stateCopy;
        case 'Back_To_Users':
            stateCopy.student = {};
            stateCopy.isView = false;
            return stateCopy;
        case 'Resend_Invites':
            return stateCopy;
        case 'Get_Section_From_Center':
            stateCopy.staffSections = action.payload.map(el => [{ value: el._id, label: el.title }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        default:
            return stateCopy;
    }
}