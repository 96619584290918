let intialState = {
    assignmentsList: [],
    questionList: [
        {
            "_id": "6648366dbae9bda17e25a022",
            "title": "<p>Question 1&nbsp;&nbsp;</p>",
            "active": true,
            "type": "MCQ",
            options: [{ _id: 1, title: 'Option 1' },
            { _id: 2, title: 'Option 2' },
            { _id: 3, title: 'Option 3' },
            { _id: 4, title: 'Option 4' }
            ],
        },
        {
            "_id": "66483693bae9bda17e25a040",
            "title": "<p>Question 2</p>",
            "active": true,
            "type": "MCQ",
            options: [{ _id: 1, title: 'Option 1' },
            { _id: 2, title: 'Option 2' },
            { _id: 3, title: 'Option 3' },
            { _id: 4, title: 'Option 4' }
            ],
        },
        {
            "_id": "6673bb54c8c9c41ca65d8a11",
            "title": "<p>My question 1</p>",
            "active": true,
            "type": "Subjective"
        },
        {
            "_id": "6673e1c7a0baaabe98ce863b",
            "title": "<p>question 2</p>",
            "active": true,
            "type": "Subjective"
        }
    ],
    attachment: [],
    assignmentLoading: false,
    quesLoading: false,
    assReports: [],
    isLoadingReport: false
}

export default function assignmentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state))
    switch (action.type) {
        case 'GET_SUBJECT_ASSIGNMENT_PROGRESS':
            stateCopy.testProgress = action.payload;
            stateCopy.testLoading = false
            if (!action.payload.length) {
                stateCopy.isListLoading = false;
            }
            stateCopy.subject = action.payload.length ? action.payload[0] : { _id: '', title: '' };
            return stateCopy;
        case 'GET_ASSIGNMENTS':
            stateCopy.assignmentsList = action.payload;
            stateCopy.data = action.payload.data;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'RESET_LOADER':
            stateCopy.isListLoading = true;
            return stateCopy;
        case "GET_INDIVIDUAL_ASSIGNMENT":
            // const ques = action.payload.questions.map(el => [{ ...el, type: el.answers?.length ? 'MCQ' : 'Subjective' }][0])
            stateCopy.questions = action.payload.questions.map(t1 => ({ ...t1, ...action.payload.q_ids.find(t2 => t2._id === t1._id) }));
            stateCopy.quesLoading = false;
            return stateCopy;
        case "GET_SUBMIT_ANSWER":
            stateCopy.submitedAnswer = Object.keys(action.payload.data)?.length === 0 ? null : action.payload.data;
            stateCopy.attachment = Object.keys(action.payload.data)?.length === 0 ? null : action.payload.data?.attachments
            return stateCopy;
        case 'Check_Assignment_Pending':
            if (action.payload) {
                const check = stateCopy.assignmentsList.data.filter(el => el.attempts === 0);
                stateCopy.assignmentsList.data = check;
            }
            else {
                stateCopy.assignmentsList.data = stateCopy.data;
            }
            return stateCopy;
        case 'Get_Upload_Url':
            stateCopy.attachment = [action.payload];
            return stateCopy;
        case 'Refresh_Attachments':
            stateCopy.attachment = [];
            stateCopy.quesLoading = true;
            return stateCopy;
        case 'Remove_Attachments':
            stateCopy.attachment = stateCopy.attachment.filter(el => el !== action.payload);
            return stateCopy;
        case "GET_SECTION_FOR_ASSIGNMENT":
            stateCopy.assignmentSections = action.payload;
            stateCopy.quesLoading = false;
            return stateCopy;
        case "GET_SECTION_STUDENTS":
            stateCopy.sectionStudents = action.payload;
            stateCopy.quesLoading = false;
            return stateCopy;
        case 'GET_SECTION_STUDENTS_QUESTIONS':
            stateCopy.evalQuestions = action.payload;
            stateCopy.quesLoading = false;
            return stateCopy;
        case 'SUBMIT_EVALUATION':
            stateCopy.evalQuestions.forEach(el => {
                if (el._id === action.payload.obj.question_id) {
                    el.answer_score = action.payload.obj.score
                }
            })
            return stateCopy;
        case 'GET_ASS_SUBJECTS':
            stateCopy.subjects = action.payload?.map(el => [{ ...el, label: el.title, value: el._id }][0]);
            stateCopy.assReports = [];
            return stateCopy;
        case 'GET_ASSIGNMENT_FOR_SELECT':
            stateCopy.assignmentSelect = action.payload?.map(el => [{ ...el, label: el.title, value: el._id }][0]);
            return stateCopy;
        case 'GENERATE_REPORT':
            stateCopy.assReports = action.payload;
            stateCopy.isLoadingReport = false;
            return stateCopy;
        case "RESET_REPORTS":
            stateCopy.assReports = [];
            stateCopy.isLoadingReport = true;
            return stateCopy;
        default:
            return stateCopy;
    }
}