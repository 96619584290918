let intialState = {
    sideMenuList: [
        {
            "text": "Dashboard",
            "action": "user-dashboard",
            "icon": "dashboard-layout-icon.svg",
            "index": 1,
            "id": "user-dashboard"
        },
        {
            "text": "My Learning",
            "action": "library",
            "icon": "classroom-layout-icon.svg",
            "index": 2,
            "id": "library"
        },
        {
            "text": "Practice Test",
            "action": "practice-test",
            "icon": "practise-layout-icon.svg",
            "index": 3,
            "id": "practice-test"
        },
        {
            "text": "Feed",
            "action": "feeds",
            "icon": "feed-layout-icon.svg",
            "index": 4,
            "id": "feeds"
        },
        {
            "text": "Achievers",
            "action": "achievers",
            "icon": "lb-layout-icon.svg",
            "index": 6,
            "id": "achievers"
        },
        // {
        //     "text": "Jobs",
        //     "action": "jobs",
        //     "icon": "job-layout-icon.svg",
        //     "index": 7,
        //     "id": "jobs"
        // },
        {
            "text": "Teacher Connect",
            "action": "connect",
            "icon": "qna-layout-icon.svg",
            "index": 11,
            "id": "connect"
        },
        {
            "text": "Assignment",
            "action": "assignments",
            "icon": "assignments-layout-icon.svg",
            "index": 17,
            "id": "assignments"
        },
        {
            "text": "Skill Library",
            "action": "skill-library",
            "icon": "skill-library-layout-icon.svg",
            "index": 11,
            "id": "skill-library"
        },
        {
            "text": "My Notes",
            "action": "notes",
            "icon": "notes-layout-icon.svg",
            "index": 14,
            "id": "notes"
        },
        {
            "text": "Notice Board",
            "action": "notice-board",
            "icon": "notice-board-layout-icon.svg",
            "index": 16,
            "id": "notice-board"
        }
    ],
    adminSideMenuList: [
        {
            "text": "My Syllabus",
            "action": "my-syllabus",
            "icon": "syllabus-layout-icon.svg",
            "index": 10,
            "id": "my-syllabus"
        },
        {
            "text": "My Content",
            "action": "my-content",
            "icon": "my-content-layout-icon.svg",
            "index": 11,
            "id": "my-content"
        },
        {
            "text": "Library",
            "action": "library",
            "icon": "classroom-layout-icon.svg",
            "index": 12,
            "id": "library"
        },
        {
            "text": "Manage Content",
            "action": "manage-content",
            "icon": "manage-content-layout-icon.svg",
            "index": 13,
            "id": "manage-content"
        },
        {
            "text": "Reports",
            "action": "reports",
            "icon": "report-layout-icon.svg",
            "index": 15,
            "id": "reports"
        },
        {
            "text": "Users",
            "action": "users",
            "icon": "classroom-layout-icon.svg",
            "index": 16,
            "id": "users"
        },
        {
            "text": "Notice Board",
            "action": "notice-board",
            "icon": "notice-board-layout-icon.svg",
            "index": 16,
            "id": "notice-board"
        },
        {
            "text": "Teacher Connect",
            "action": "connect",
            "icon": "qna-layout-icon.svg",
            "index": 11,
            "id": "connect"
        },
        {
            "text": "Assignment",
            "action": "assignments",
            "icon": "assignments-layout-icon.svg",
            "index": 17,
            "id": "assignments"
        },
        {
            "text": "Account",
            "action": "account",
            "icon": "account-active-icon.svg",
            "index": 17,
            "id": "account"
        },
    ],
    facultySideMenuList: [
        {
            "text": "My Syllabus",
            "action": "my-syllabus",
            "icon": "syllabus-layout-icon.svg",
            "index": 10,
            "id": "my-syllabus"
        },
        {
            "text": "My Content",
            "action": "my-content",
            "icon": "my-content-layout-icon.svg",
            "index": 11,
            "id": "my-content"
        },
        {
            "text": "Library",
            "action": "library",
            "icon": "classroom-layout-icon.svg",
            "index": 12,
            "id": "library"
        },
        {
            "text": "Reports",
            "action": "reports",
            "icon": "report-layout-icon.svg",
            "index": 15,
            "id": "reports"
        },
        {
            "text": "Notice Board",
            "action": "notice-board",
            "icon": "notice-board-layout-icon.svg",
            "index": 16,
            "id": "notice-board"
        },
        {
            "text": "Teacher Connect",
            "action": "connect",
            "icon": "qna-layout-icon.svg",
            "index": 11,
            "id": "connect"
        },
        {
            "text": "Assignment",
            "action": "assignments",
            "icon": "assignments-layout-icon.svg",
            "index": 17,
            "id": "assignments"
        },
    ],
    smeSideMenuList: [
        {
            "text": "My Syllabus",
            "action": "my-syllabus",
            "icon": "syllabus-layout-icon.svg",
            "index": 10,
            "id": "my-syllabus"
        },
        {
            "text": "My Content",
            "action": "my-content",
            "icon": "my-content-layout-icon.svg",
            "index": 11,
            "id": "my-content"
        },
        {
            "text": "Library",
            "action": "library",
            "icon": "classroom-layout-icon.svg",
            "index": 12,
            "id": "library"
        },
        {
            "text": "Manage Content",
            "action": "manage-content",
            "icon": "manage-content-layout-icon.svg",
            "index": 13,
            "id": "manage-content"
        },
        {
            "text": "Reports",
            "action": "reports",
            "icon": "report-layout-icon.svg",
            "index": 15,
            "id": "reports"
        },
        {
            "text": "Notice Board",
            "action": "notice-board",
            "icon": "notice-board-layout-icon.svg",
            "index": 16,
            "id": "notice-board"
        },
        {
            "text": "Assignment",
            "action": "assignments",
            "icon": "assignments-layout-icon.svg",
            "index": 17,
            "id": "assignments"
        },
    ],

    isSuggestion: false
}

export default function sideBarReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Side_Menu_List':
            // stateCopy.sideMenuList = action.payload.map(item => ({ ...item, id: item.action }));
            stateCopy.sideMenuList = localStorage.getItem('role').includes('master') || localStorage.getItem('role').includes('admin') ? stateCopy.adminSideMenuList :
                localStorage.getItem('role') === 'faculty' ? stateCopy.facultySideMenuList : localStorage.getItem('role') === 'sme' ? stateCopy.smeSideMenuList : localStorage.getItem('role') === 'program manager' ? stateCopy.smeSideMenuList : stateCopy.sideMenuList
            return stateCopy;
        case 'Get_Student_Details':
            stateCopy.studentDetails = action.payload;
            localStorage.setItem('center', action.payload.center_id)
            return stateCopy;
        case 'Get_Popup_Notifications':
            stateCopy.notifications = action.payload.data;
            stateCopy.unread = action.payload.unread;
            return stateCopy;
        case 'Marked_Notification':
            window.location.href = action.payload;
            return stateCopy;
        case 'Get_Profile_Details':
            stateCopy.profileDetails = action.payload;
            localStorage.setItem('id', stateCopy.profileDetails._id)
            return stateCopy;
        case 'Intro_Complete':
            localStorage.setItem("introComplete", JSON.stringify(true));
            return stateCopy;
        case 'Get_Suggestions':
            stateCopy.suggestions = action.payload;
            return stateCopy;
        case 'Open_Suggestion':
            stateCopy.isSuggestion = true;
            return stateCopy;
        case 'Close_Suggestion':
            stateCopy.isSuggestion = false;
            return stateCopy;
        case 'Logout_Done':
            return stateCopy
        default:
            return stateCopy;

    }
}