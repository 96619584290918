let intialState = {
    isLoading: true,
    noResults: false,
    resource: {}
}

export default function searchReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Content_Data':
            if (action.payload.length > 0) {
                stateCopy.searchContent = action.payload;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.noResults = true;
            }
            return stateCopy;
        case 'Get_Content_Filters':
            stateCopy.feedsType = action.payload;
            return stateCopy;
        case 'Get_Next_Content_Data':
            stateCopy.searchContent = stateCopy.searchContent.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Get_Search_Feed_Resource':
            stateCopy.isFeed = true;
            stateCopy.resource = action.payload;
            return stateCopy;
        case 'Close_Search_Feed':
            stateCopy.isFeed = false;
            stateCopy.resource = {};
            return stateCopy;
        case 'Get_Search_Feed_Like':
            if (action.payload.search === 'search') {
                const feeds = stateCopy.searchContent.map(answer => {
                    if (answer.id === action.payload.id)
                        return {
                            id: answer.id,
                            image: answer.image,
                            title: answer.title,
                            description: answer.description,
                            url: answer.url,
                            type: answer.type,
                            skill: answer.skill,
                            liked: !answer.liked,
                            bookmarked: answer.bookmarked,
                            source: answer.source,
                            video_id: answer.video_id,
                            likesCount: answer.likesCount - 1,
                            viewCount: answer.viewCount,
                        }
                    return answer
                })
                stateCopy.searchContent = feeds;
                if (stateCopy.resource !== {}) {
                    stateCopy.resource.liked = !action.payload.liked;
                }
            }
            else {
               stateCopy.searchedResource.forEach(el => {
                   if(el._id === action.payload.id){
                       el.liked = action.payload.liked ?false :true
                   }
               });
                if (stateCopy.resource !== {}) {
                    stateCopy.resource.liked = !action.payload.liked;
                }
            }

            return stateCopy;
        case 'Search_Share_Popup_Open':
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case 'Search_Share_Popup_Close':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Search_Share_Done':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Type_Filter_Clicks':
            stateCopy.isLoading = true;
            stateCopy.noResults = false;
            stateCopy.feedsType = stateCopy.feedsType.map(answer => {
                if (answer.title === action.payload.title) return { value: !action.payload.value, title: answer.title }
                return answer
            });
            return stateCopy;
        case 'Reset_SearchFeed_Loading':
            stateCopy.isLoading = true;
            stateCopy.noResults = false;
            return stateCopy;
        case 'Search_Resources':
            stateCopy.searchedResource = action.payload.resource;
            return stateCopy;
        case 'Search_Next_Resources':
            stateCopy.searchedResource = stateCopy.searchedResource.concat(action.payload.resource);
            return stateCopy;
        default:
            return stateCopy;

    }
}