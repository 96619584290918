/* eslint-disable no-redeclare */
export function reverseArr(input) {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}

export function validatePhone(txtPhone) {
    var filter = /[6-9]{1}[0-9]{9}/;
    if (filter.test(txtPhone)) {
        return true;
    }
    else {
        return false;
    }
}

export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/slide-next.svg" width='45px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/slide-prev.svg" width='45px' alt='' />
        </div>
    );
}

export const nFormatter = (num) => {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(1).replace(rx, "$1") + si[i].symbol;
}
export const renameProperties = (sourceObj, replaceList, destObj) => {
    destObj = destObj || {};
    each(sourceObj, function (key) {
        if (sourceObj.hasOwnProperty(key)) {
            if (typeof sourceObj[key] === 'object') {
                if (replaceList[key]) {
                    var newName = replaceList[key];
                    destObj[newName] = {};
                    renameProperties(sourceObj[key], replaceList, destObj[newName]);
                } else if (!replaceList[key]) {
                    destObj[key] = {};
                    renameProperties(sourceObj[key], replaceList, destObj[key]);
                }
            } else {
                if (replaceList[key]) {
                    var newName = replaceList[key];
                    destObj[newName] = sourceObj[key];
                } else if (!replaceList[key]) {
                    destObj[key] = sourceObj[key];
                }
            }
        }
    });

    return destObj;
}
function each(objOrArr, callBack) {
    if (objOrArr instanceof Array) {
        for (var i = 0; i < objOrArr.length; i++) {
            callBack(i);
        }
    } else if (typeof objOrArr === 'object') {
        for (var prop in objOrArr) {
            // if the property really exist
            if (objOrArr.hasOwnProperty(prop)) {
                callBack(prop);
            }
        }
    }
}

export const renameArrProperties = (arr) => {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
        var obj = {};
        obj.label = arr[i];
        obj.value = arr[i]
        newArr.push(obj);
    }
    return newArr
}

export const getSemesterArr = (len) => {
    var arr = [];
    for (var i = 0; i < len; i++) {
        arr.push({
            label: 'Semester ' + (i + 1),
            value: i + 1
        });
    }
    return arr;
}

export const secondsToTime = (e) => {
    // var h = Math.floor(e / 3600).toString().padStart(2,'0'),
    var m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');

    return m + ':' + s;
    //return `${h}:${m}:${s}`;
}

export const convertDate = (d1) => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(d1)
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(date)
    return [pad(d.getDate()), pad(monthNames[d.getMonth()]), pad(d.getFullYear())].join('-')
}

export const formatAMPM = (d) => {
    var date = new Date(d)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getDateObj = (time) => {
    var startTime = new Date();
    var parts = time.match(/(\d+):(\d+) (AM|PM)/);
    if (parts) {
        var hours = parseInt(parts[1]),
            minutes = parseInt(parts[2]),
            tt = parts[3];
        if (tt === 'PM' && hours < 12) hours += 12;
        startTime.setHours(hours, minutes, 0, 0);
    }
    return startTime
}


export function convertToDate(str) {
    if (str === null) {
        return null
    } else {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }
}

export function convertToDate1(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
}

export const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    return `${h}${m === 0 ? '' : ':'}${m === 0 ? '' : m}`;
}

export function convertTONumber(start, end) {
    // start = start.split(' ')[0].split(":");
    // end = end.split(' ')[0].split(":");
    // var startDate = new Date(date+ start[0], start[1], 0);
    // var endDate = new Date(date + end[0], end[1], 0);
    var startDate = getDateObj(start);
    var endDate = getDateObj(end);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff += (hours * 1000 * 60 * 60) / 1000 / 60;
    var minutes = Math.floor(diff / 1000 / 60);
    return minutes
}

export function generate_year_range(start, end) {
    var years = [];
    for (var year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
}


export const getSchedulesHours = (schedules) => {
    const data = schedules.map((el) => [{ total: convertTONumber(el.start, el.end, el.date) }][0]);
    const sum = data.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total,
        0,
    );
    return sum
}

export const getYesterdayDate = () => {
    let now = new Date();
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    return convertDate(defaultDate)
}

export function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

// const maxLengthCheck = (object) => {
//     if (object.target.value.length > object.target.maxLength) {
//         object.target.value = object.target.value.slice(0, object.target.maxLength)
//     }
//     else if (object.target.value.length === 0) {
//         setMobileError('');

//     }
//     else if (object.target.value.length < object.target.maxLength) {
//         setMobileError('Please enter 10 digit mobile number')
//     }

//     else if (object.target.value.length === object.target.maxLength) {
//         setMobileError('');
//     }
// }